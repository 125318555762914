import { useState } from "react";
import mixpanel from "mixpanel-browser";

const useMixpanel = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const initMixpanel = () => {
    if (!isInitialized) {
      const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

      if (mixpanelToken) {
        console.log("Initializing Mixpanel with token:", mixpanelToken);
        mixpanel.init(mixpanelToken, {
          debug: true,
          track_pageview: true,
          persistence: "localStorage",
        });
        setIsInitialized(true);
      } else {
        console.error("Mixpanel token is undefined or missing.");
      }
    } else {
      console.log("Mixpanel is already initialized.");
    }
  };

  const identifyUser = (userId: string) => {
    if (isInitialized) {
      mixpanel.identify(userId);
    } else {
      console.warn(
        "identifyUser : Mixpanel is not initialized yet. Cannot identify user."
      );
    }
  };

  const setUserProperties = (properties: Record<string, any>) => {
    if (isInitialized) {
      mixpanel.people.set(properties);
    } else {
      console.warn(
        "setUserProperties : Mixpanel is not initialized yet. Cannot set user properties."
      );
    }
  };

  const trackEvent = (eventName: string, properties?: Record<string, any>) => {
    if (isInitialized) {
      mixpanel.track(eventName, properties);
    } else {
      initMixpanel();
      console.warn(
        "trackEvent : Mixpanel is not initialized yet. Cannot track event."
      );
      mixpanel.track(eventName, properties);
    }
  };

  return {
    initMixpanel,
    identifyUser,
    setUserProperties,
    trackEvent,
  };
};

export default useMixpanel;
