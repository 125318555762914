import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import useMixpanel from "../../hooks/useMixpanel";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/ArrowRight.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/chevron-down.svg";
import {
  profile1,
  profile2,
  profile3,
  profile4,
  star,
  popupProfile1,
  popupProfile2,
  popupProfile3,
  popupProfile4,
  imageSection6,
} from "../../assets/images";

const faqs = [
  {
    question: "도넛은 어떤 서비스인가요?",
    answer: `도넛은 창의적인 일을 하는 사람들을 위한 멤버십\n서비스이에요. 창업 고민부터 연애 상담까지 나와\n관심사가 맞는 사람을 만날 수 있어요.`,
  },
  {
    question: "도넛에는 어떤 사람들이 있나요?",
    answer:
      "도넛은 새로운 경험에 열려있는 창의적인 사람들이\n모여 있어요. 현재는 주로 스타트업, 테크, 디자인\n영역의 사람들이 도넛에서 활동 중이에요.",
  },
  {
    question: "내 프로필은 누구에게 공개되나요?",
    answer:
      "매일 아침 10시, 나와 관심사가 맞는 사람에게\n내 프로필이 전달돼요.",
  },
  {
    question: "도넛을 쓰고 싶어요! 어떻게 해야 되나요?",
    answer:
      "도넛은 기존 멤버의 초대로 들어올 수 있어요.\n만약 초대를 받지 못했다면 프로필을 만들어주세요. \n도넛 커뮤니티에 어울리는 분이라면 3일 내로 가입이\n진행돼요.",
  },
  {
    question: "도넛은 무료 서비스인가요?",
    answer:
      "도넛의 멤버십 비용은 월 29,000원이에요.\n지금 신청하면 3개월간 무료로 도넛 멤버십을 이용할\n수 있어요!",
  },
  {
    question: "왜 초대나 심사를 통해서만 이용할 수 있나요?",
    answer:
      "무엇을 하는지보다 누구와 하는지가 더 중요하다고\n생각했어요. 서로가 잘 맞는다고 느낄 사람들을 모으기\n위해서 초대 기능을 개발했어요. 내 친구의 친구는\n나랑도 잘 맞을 확률이 높으니까요.",
  },
  {
    question: "도넛을 왜 만들게 되었나요?",
    answer:
      "기술의 발전으로 많은 것들이 온라인으로 대체되고\n있지만, 실제 사람과의 만남은 대체될 수 없다고\n생각했어요. 근데 우리들은 점점 더 부담 없는걸 \n찾잖아요. 그래서 도넛을 통해 느슨하면서도 끈끈한\n관계를 찾게 도와주고 싶었어요.",
  },
  {
    question: "왜 도넛인가요?",
    answer:
      "도넛은 손쉽게 즐거움을 주잖아요. 손쉽게 먹을 수\n있으면서도 즐거움을 주는 도넛이 우리에게 필요한\n새로운 관계에 어울린다고 생각했어요.",
  },
];

const FAQItem = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div className="question" onClick={toggleOpen}>
        <span className="title">Q {question}</span>
        <span className={`arrow ${isOpen ? "open" : ""}`}>
          <ArrowDownIcon />
        </span>
      </div>
      {isOpen && (
        <div className="answer" style={{ whiteSpace: "pre-line" }}>
          {answer}
        </div>
      )}
    </div>
  );
};

const Home: React.FC = () => {
  const { trackEvent } = useMixpanel();
  const [selectedProfile, setSelectedProfile] = useState<number>(0);
  const navigate = useNavigate();

  const togglePopup = (profileId: number) => {
    setSelectedProfile(profileId);
    if (selectedProfile === 0) {
      trackEvent("home-profiles button-click");
    }
  };

  const closePopup = () => {
    setSelectedProfile(0);
  };

  useEffect(() => {
    trackEvent("home landed");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    trackEvent("home-survey button-click");
    navigate("/enroll");
  };

  const getProfileImage = (profileId: number) => {
    switch (profileId) {
      case 1:
        return popupProfile1;
      case 2:
        return popupProfile2;
      case 3:
        return popupProfile3;
      case 4:
        return popupProfile4;
      default:
        return "";
    }
  };

  return (
    <>
      <section className="hero">
        <div className="container">
          <h1>느슨한 관계의 시작, 도넛</h1>
          <p>
            스타트업, 테크, 디자인 등<br />
            창의적인 일을 하는 멤버들을 만나보세요
          </p>
        </div>
        <div className="hero-background"></div>
      </section>
      <section className="section1">
        <div className="container">
          <h2>
            매일 아침 10시 <br />
            도넛 멤버를 소개해드려요
          </h2>
          <p>
            나와 관심사가 맞는 멤버들과
            <br />
            톡톡 튀는 대화를 나눠보세요
          </p>
        </div>
        <div className="section1-background"></div>
      </section>
      <section className="section2">
        <div className="container">
          <h2>
            연애나 비즈니스
            <br />
            목적이 아니에요
          </h2>
          <p>
            다양한 멤버들과
            <br />
            느슨하게 연결될 수 있어요
          </p>
        </div>
        <div className="section2-background"></div>
      </section>
      <section className="section3">
        <div className="container">
          <h2>
            의미 없는 채팅 말고
            <br />
            만나서 대화해요 우리
          </h2>
          <p>장소 추천도 도넛이 책임질게요</p>
        </div>
        <div className="section3-background"></div>
      </section>

      <section className="section6">
        <div className="container">
          <div className="title-container">
            <h2>
              도넛에는 이런 배경의
              <br />
              멤버들이 활동 중이에요
            </h2>
          </div>
          <img
            src={imageSection6}
            alt={"도넛 회사"}
            className="image-section6"
          />
        </div>
      </section>

      <section className="section5">
        <div className="container">
          <div className="title-container">
            <h2>
              도넛 커뮤니티에
              <br />
              지원해보세요
            </h2>
            <img src={star} alt="star" className="star-icon" />
          </div>
          <p>
            서울의 첫 150명을 위한 혜택
            <br />
            3개월 멤버십과 친구 초대권 1장
          </p>
          <button onClick={handleClick} className="cta-button">
            Let’s Donut!
          </button>
        </div>
      </section>
      <section className="section4">
        <div className="container">
          <h2>도넛을 만든 사람들</h2>
          <p className="intro-text">프로필을 열어보세요</p>
          <div className="profile-scroll">
            <div className="profile-card" onClick={() => togglePopup(1)}>
              <img src={profile1} alt="profile1" />
              <div className="right-content">
                <h3>Sid</h3>
                <p>테크 · 창업자 · ENTP</p>
                <div className="tags">
                  <span>전시</span>
                  <span>농구</span>
                  <span>맛집</span>
                </div>
                <div className="see-more">
                  <p>자세히 보기</p>
                  <ArrowRightIcon />
                </div>
              </div>
            </div>
            <div className="profile-card" onClick={() => togglePopup(2)}>
              <img src={profile2} alt="profile1" />
              <div className="right-content">
                <h3>Docky</h3>
                <p>테크 · 개발자 · INTJ</p>
                <div className="tags">
                  <span>건축</span>
                  <span>골프</span>
                  <span>드라이브</span>
                </div>
                <div className="see-more">
                  <p>자세히 보기</p>
                  <ArrowRightIcon />
                </div>
              </div>
            </div>
            <div className="profile-card" onClick={() => togglePopup(3)}>
              <img src={profile3} alt="profile1" />
              <div className="right-content">
                <h3>Celina</h3>
                <p>테크 · 기획자 · INFP</p>
                <div className="tags">
                  <span>환경</span>
                  <span>글쓰기</span>
                  <span>전시</span>
                </div>
                <div className="see-more">
                  <p>자세히 보기</p>
                  <ArrowRightIcon />
                </div>
              </div>
            </div>
            <div className="profile-card" onClick={() => togglePopup(4)}>
              <img src={profile4} alt="profile1" />
              <div className="right-content">
                <h3>Hazel</h3>
                <p>테크 · 디자이너 · INFJ</p>
                <div className="tags">
                  <span>️예술</span>
                  <span>카페</span>
                  <span>여행</span>
                </div>
                <div className="see-more">
                  <p>자세히 보기</p>
                  <ArrowRightIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-section">
        <div className="container">
          <h2>자주 묻는 질문</h2>
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </section>
      {selectedProfile > 0 && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="profile-popup">
            <div className="profile-content">
              <img
                src={getProfileImage(selectedProfile)}
                alt={`profile-${selectedProfile}`}
                className="profile-image"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
