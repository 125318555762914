import apiClient from "./apiClient";

interface formSendFormat {
  phone: string;
  name: string;
  age: string;
  job: string;
  field: string;
  interests: string;
  cities: string;
  languages: string;
  question1: string;
  referrerCode: string;
  snsIds: string;
}

interface SlackMessage {
  channel: string;
  title: string;
  message: formSendFormat;
}

export const sendSlackMessage = async (data: SlackMessage): Promise<void> => {
  try {
    console.log("data", data);
    const response = await apiClient.post("/api/send-slack", data);
    console.log("Message sent successfully:", response.data);
  } catch (error) {
    console.error("Failed to send Slack message:", error);
  }
};
