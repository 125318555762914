import apiClient from "./apiClient";

interface UserFormData {
  name: string;
  phone: string;
  age: number;
  field: string;
  job: string;
  interests: string[];
  cities: string[];
  languages: string[];
  question1: string;
  referrerCode: string;
  snsIds: string[];
}

export const createUser = async (userData: UserFormData): Promise<any> => {
  try {
    const response = await apiClient.post("/donut/user", userData);
    console.log("User created successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};
