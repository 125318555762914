import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import { ReactComponent as LogoBlack } from "../../assets/icons/logo_black.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/Share.svg";
import useMixpanel from "../../hooks/useMixpanel";
import { shareOnMobile } from "react-mobile-share";
interface HeaderProps {
  showShareButton?: boolean;
  isHome?: boolean;
}

const Header: React.FC<HeaderProps> = ({ showShareButton, isHome }) => {
  const { trackEvent } = useMixpanel();
  const navigate = useNavigate();
  const isAndroid = /Android/i.test(navigator.userAgent);

  const handleClickShareButton = () => {
    trackEvent("home-share button-click");
    shareOnMobile({
      title: "도넛 - 느슨한 관계의 시작",
      text: "느슨하지만 다양하게, 준비 도넛나요?",
      url: window.location.href,
    });
    // if (navigator.share) {
    //   navigator
    //     .share({
    //       title: "도넛 - 느슨한 관계의 시작",
    //       text: "느슨하지만 다양하게, 준비 도넛나요?",
    //       url: window.location.href,
    //     })
    //     .then(() => console.log("Successful share"))
    //     .catch((error) => console.log("Error sharing", error));
    // } else if (isAndroid) {
    //   // 안드로이드 인앱 브라우저 대체 방법
    //   alert(
    //     "공유하려면 링크를 길게 눌러 복사하거나, 다른 방법으로 공유하세요."
    //   );
    //   window.open(window.location.href, "_blank");
    // } else {
    //   alert("Your browser does not support the Web Share API");
    // }
  };

  const handleClick = () => {
    trackEvent("home-survey button-click");
    navigate("/enroll");
  };

  return (
    <header className="header">
      <div className={`header_wrapper ${isHome ? "" : "no_shadow"}`}>
        <div className="header_logo">
          <Link to="/" className="header_logo_link">
            <LogoBlack />
          </Link>
        </div>
        {showShareButton && !isAndroid && (
          <button className="share-button" onClick={handleClickShareButton}>
            <ShareIcon style={{ width: 24, height: 24 }} />
          </button>
        )}
      </div>
      {isHome && (
        <div className="header_cta_wrapper" onClick={handleClick}>
          <div className="header_cta">
            <p>도넛 멤버십 혜택 받기</p>
            <div className="button">
              <p>Let’s Donut!</p>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
