import React from "react";
import "./Loading.css";
import { Circles, ThreeDots } from "react-loader-spinner";

interface LoadingProps {
  text?: string;
  transparent?: boolean;
}

const Loading: React.FC<LoadingProps> = ({
  text = "",
  transparent = false,
}) => {
  if (transparent) {
    return (
      <div className="transparent-loading-container">
        <div className="loading-spinner-wrapper">
          <ThreeDots color="#181818" height={80} width={80} />
        </div>
        <p className="loading-content">{text}</p>
      </div>
    );
  }

  return (
    <div className="loading-container">
      <div className="loading-spinner-wrapper">
        <Circles color="#000" height={80} width={80} />
      </div>
      <p className="loading-content">{text}</p>
    </div>
  );
};

export default Loading;
