import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://127.0.0.1:8000/",
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // 여기서 에러를 처리합니다. 예를 들어, 토큰 만료 시 로그아웃 등.
    return Promise.reject(error);
  }
);

export default apiClient;
