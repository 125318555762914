import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Home, Enroll, FAQ, About } from "./pages";

const AppContent: React.FC = React.memo(() => {
  const [showShareButton, setShowShareButton] = useState(true);
  const location = useLocation();

  return (
    <div className="App">
      <Header
        showShareButton={location.pathname === "/"}
        isHome={location.pathname === "/"}
      />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/enroll" element={<Enroll />} />
        </Routes>
      </main>
      {location.pathname === "/" && <Footer />}{" "}
    </div>
  );
});

const App: React.FC = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
