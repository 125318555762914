import React from "react";
import "./Footer.css";
import { ReactComponent as LogoBlack } from "../../assets/icons/logo_black.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as KakaoIcon } from "../../assets/icons/kakaotalk.svg";
import useMixpanel from "../../hooks/useMixpanel";

const Footer: React.FC = () => {
  const { trackEvent } = useMixpanel();
  const handleClickSocialIcon = () => {
    trackEvent("home-sns button-click");

    window.location.href = "https://www.instagram.com/donut__app";
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="icons">
          <LogoBlack />
          <div className="social-icon" onClick={handleClickSocialIcon}>
            <InstagramIcon />
          </div>
        </div>
        <div className="footer-content">
          <p>느슨한 관계의 시작, 도넛</p>
          <address>
            서울 마포구 마포대로 122 프론트원
            <br />
            사업자등록번호: 528-88-02881
            <br />
            통신판매번호: 2024-서울마포-1394
            <br />
            개인정보 책임 관리자: 신현우
          </address>
        </div>
        <div className="footer-links">
          <a href="https://coffeeting.notion.site/0d321fdca73445e286ab89223cec657a">
            개인정보 취급방침
          </a>
          <a href="https://coffeeting.notion.site/12f7bd36116b4dfcbe82e1fada02a839">
            이용약관
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
