import React, { useState } from "react";
import { StepForm } from "../../components";
import "./Enroll.css";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/ArrowLeft.svg";
import { useFormStore } from "../../store/formStore";
import { sendSlackMessage } from "../../api/slackService";
import useMixpanel from "../../hooks/useMixpanel";
import { useNavigate } from "react-router-dom";
import { createUser } from "../../api/userService";
import Loading from "../../components/Loading/Loading";

const Enroll: React.FC = () => {
  const [step, setStep] = useState(1);
  const { formData, setFormData } = useFormStore();
  const { trackEvent } = useMixpanel();
  const navigate = useNavigate();
  const [enrollCardImageUrl, setEnrollCardImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userNumber, setUserNumber] = useState();

  const updateUser = async () => {
    const res = await createUser(formData);
    console.log("file_path", res.file_path);

    const enroll_card_image_url = "https://d1nu9ge6hx6v4o.cloudfront.net/";
    setEnrollCardImageUrl(enroll_card_image_url + res.file_path);
    setUserNumber(res.user_id);
    console.log("enroll_card_image_url", enrollCardImageUrl);
  };

  const handleNext = async () => {
    if (step === 11) {
      setIsLoading(true);
      await updateUser();
      setStep(12);
      setIsLoading(false);
      trackEvent("survey-done");
      const channel = "donut";
      const title = "등록 완료";
      const message = {
        name: formData.name,
        phone: formData.phone,
        age: String(formData.age),
        job: formData.job,
        field: formData.field,
        interests: formData.interests.join(","),
        cities: formData.cities.join(","),
        languages: formData.languages.join(","),
        question1: formData.question1,
        referrerCode: formData.referrerCode,
        snsIds: formData.snsIds?.join(","),
      };
      sendSlackMessage({ channel, title, message });
    } else if (step >= 12) {
      navigate("/");
    } else {
      setStep((prevStep) => prevStep + 1);
      if (step === 9) {
        trackEvent("survey-story-click");
      }
    }
  };

  const handlePrev = () => {
    if (step === 1) {
      navigate("/");
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(name as keyof typeof formData, value);
  };

  const handleMultiSelect = (value: string, field: keyof typeof formData) => {
    const currentValues = formData[field] as string[];
    if (currentValues.includes(value)) {
      setFormData(
        field,
        currentValues.filter((item) => item !== value)
      );
    } else if (currentValues.length < 5) {
      setFormData(field, [...currentValues, value]);
    }
  };

  const handleDisableNext = (
    step: number,
    formItemData: typeof formData
  ): boolean => {
    switch (step) {
      case 1:
        // skip referrerCode
        return false;
      case 2:
        return formItemData.name?.length < 1;
      case 3:
        return formItemData.phone?.length < 11;
      case 4:
        return formItemData.age < 19;
      case 5:
        return formItemData.field?.length < 1;
      case 6:
        return formItemData.job?.length < 1;
      case 7:
        return formItemData.interests?.length < 3;
      case 8:
        return !(
          formItemData.cities?.length > 0 &&
          formItemData.cities?.[0]?.length > 0
        );
      case 9:
        return formItemData.languages.length === 0;
      case 10:
        return !formItemData.snsIds || formItemData.snsIds?.length < 1;
      case 11:
        return formItemData.question1?.length <= 5;
      default:
        return false;
    }
  };

  const handleSkip = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleCityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;

    // 기존 formData의 cities 배열을 가져와 수정합니다.
    const updatedCities = [...formData.cities];
    updatedCities[index] = value;

    // setFormData를 호출하여 변경된 cities 배열을 저장합니다.
    setFormData("cities", updatedCities);
  };

  const handleLanguageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;

    // 기존 formData의 cities 배열을 가져와 수정합니다.
    const updatedLanguages = [...formData.languages];
    updatedLanguages[index] = value;

    // setFormData를 호출하여 변경된 Languages 배열을 저장합니다.
    setFormData("languages", updatedLanguages);
  };

  const handleSNSIdsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;

    // 기존 formData의 cities 배열을 가져와 수정합니다.
    const updatedsnsIds = [...(formData.snsIds ?? [])];
    updatedsnsIds[index] = value;

    // setFormData를 호출하여 변경된 Languages 배열을 저장합니다.
    setFormData("snsIds", updatedsnsIds);
  };

  const handleQuestionChange = (step: number) => {
    setStep(step);
  };

  return (
    <>
      <div className="enroll-container">
        <div className={`form-header header-${step}`}>
          {step < 12 && (
            <button className="back-button" onClick={handlePrev}>
              <ArrowLeftIcon />
            </button>
          )}
        </div>
        <StepForm
          step={step}
          formData={formData}
          handleNext={handleNext}
          handleChange={handleChange}
          handleMultiSelect={handleMultiSelect}
          disableNext={handleDisableNext(step, formData)}
          handleSkip={handleSkip}
          handleCityChange={handleCityChange}
          handleLanguageChange={handleLanguageChange}
          handleQuestionChange={handleQuestionChange}
          enrollCardImageUrl={enrollCardImageUrl}
          handleSNSIdsChange={handleSNSIdsChange}
          userNumber={userNumber}
        />
      </div>
      {isLoading && <Loading text="등록 중입니다.." transparent />}
    </>
  );
};

export default Enroll;
