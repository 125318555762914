import React from "react";
import "./StepForm.css";
import { shareOnMobile } from "react-mobile-share";

interface StepFormProps {
  step: number;
  formData: {
    phone: string;
    name: string;
    age: number;
    job: string;
    field: string;
    interests: string[];
    cities: string[];
    languages: string[];
    question1: string;
    referrerCode: string;
    snsIds: string[];
  };
  handleNext: () => void;
  handleChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  handleMultiSelect: (
    value: string,
    field: keyof StepFormProps["formData"]
  ) => void;
  disableNext: boolean;
  handleSkip: () => void;
  handleCityChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleLanguageChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleQuestionChange: (step: number) => void;
  enrollCardImageUrl?: string;
  userNumber?: number;
  handleSNSIdsChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
}

const interestCultureOptions = [
  "🎧 공연/페스티벌",
  "📚 독서",
  "✍️ 글쓰기",
  "📸 사진",
  "🎨 미술",
  "🌺 꽃꽂이",
  "🖼 전시",
  "🎤 노래 부르기",
  "🎹 악기 연주",
  "⚱️ 공예",
  "🕺 댄스",
  "🎵 음악듣기",
];
const interestSportsOptions = [
  "⚾️ 야구",
  "🏃‍♂️ 러닝",
  "🧘‍♀️ 요가/필라테스",
  "🚴‍♂️ 자전거",
  "🎾 테니스",
  "🧗‍♀️ 클라이밍",
  "🏋️‍♂️ 헬스",
  "⚽️ 축구",
  "🏀 농구",
  "⛳️ 골프",
  "🏔 등산",
  "🚶‍♂️ 산책",
];
const interestDailyOptions = [
  "🎃 웹툰",
  "🎬 영화/드라마",
  "🌮 맛집",
  "📺 애니메이션",
  "☕️ 카페",
  "🕹️ 게임",
  "🚗 드라이브",
  "🍳 요리",
  "✈️ 여행",
  "🐶 반려동물",
  "🛒 쇼핑",
  "📖 자기계발",
  "💰 재테크",
];
const fieldOptions = [
  "문화 · 예술",
  "게임",
  "건축",
  "디자인",
  "광고 마케팅",
  "패션 · 뷰티",
  "교육",
  "테크",
  "제조 · 생산",
  "엔터테인먼트",
  "금융",
  "법률",
  "푸드",
  "환경",
  "미디어",
  "의료 · 바이오",
  "헬스케어",
];

const StepForm: React.FC<StepFormProps> = ({
  step,
  formData,
  handleNext,
  handleChange,
  handleMultiSelect,
  disableNext,
  handleSkip,
  handleCityChange,
  handleLanguageChange,
  handleQuestionChange,
  enrollCardImageUrl,
  userNumber,
  handleSNSIdsChange,
}) => {
  const handlePhoneNumberKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const allowedKeys = [
      "Backspace",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Enter",
    ];
    const isNumberKey = e.key >= "0" && e.key <= "9";

    if (!isNumberKey && !allowedKeys.includes(e.key)) {
      e.preventDefault(); // 숫자 외의 문자 차단
    }
  };

  const handlePhoneNumberInput = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget;
    const cleanedValue = input.value.replace(/[^0-9]/g, ""); // 숫자 외의 문자 제거

    if (cleanedValue.length > 11) {
      input.value = cleanedValue.slice(0, 11); // 최대 11자로 제한
    } else {
      input.value = cleanedValue;
    }
  };

  const handleAgeInput = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget;
    const cleanedValue = input.value.replace(/[^0-9]/g, "");

    if (cleanedValue.length > 2) {
      input.value = cleanedValue.slice(0, 2);
    } else {
      input.value = cleanedValue;
    }
  };

  // const handleMBTIInput = (e: React.FormEvent<HTMLInputElement>) => {
  //   const input = e.currentTarget;

  //   // 영문자만 남기고, 소문자를 대문자로 변환
  //   const uppercaseValue = input.value.replace(/[^a-zA-Z]/g, "").toUpperCase();

  //   input.value = uppercaseValue;
  // };

  const handleDownload = async (imageUrl: string) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      const newWindow = window.open(imageUrl, "_blank");
      if (newWindow) {
        newWindow.focus();
      }
    } else {
      try {
        const response = await fetch(imageUrl, { mode: "cors" });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "enroll_card_16.png"; // 다운로드될 파일명
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // 메모리 해제
      } catch (error) {
        alert("이미지 다운로드가 안돼요");
        shareOnMobile({
          title: "도넛 신청 완료!",
          text: "프로필이 완성되었어요~ 창의적인 친구에게 공유해 보세요.",
          url: imageUrl,
        });
      }
    }

    // if (isInAppBrowser()) {
    //   // 인앱 브라우저에서는 직접 열기

    //   // window.open(imageUrl, "_blank");
    //   shareOnMobile({
    //     title: "도넛 신청 완료!",
    //     text: "프로필이 완성되었어요~ 창의적인 친구에게 공유해 보세요.",
    //     url: imageUrl,
    //   });

    //   setTimeout(() => {
    //     // navigator.share({
    //     //   title: "도넛 신청 완료!",
    //     //   text: "프로필이 완성되었어요~ 창의적인 친구에게 공유해 보세요.",
    //     //   url: imageUrl,
    //     // });
    //   }, 1000);
    // } else {

    // }
  };

  const isInAppBrowser = () => {
    const ua = navigator.userAgent;
    return (
      ua.includes("Instagram") ||
      ua.includes("FBAN") ||
      ua.includes("KakaoTalk") ||
      ua.includes("Line") ||
      ua.includes("NAVER")
    );
  };

  return (
    <div className="step-form-container">
      <div className="form-content">
        {step === 1 && (
          <div className="step-1">
            <h3>초대 받고 오셨나요?</h3>
            <p>초대 받지 않았다면 건너뛰기</p>
            <input
              type="text"
              name="referrerCode"
              value={formData.referrerCode}
              onChange={handleChange}
              placeholder="초대 코드"
            />
          </div>
        )}

        {step === 2 && (
          <div className="step-2">
            <h3>이름을 입력해주세요</h3>
            <p>실명을 입력해주세요</p>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="김도넛"
            />
          </div>
        )}

        {step === 3 && (
          <div>
            <h3>전화번호를 입력해주세요</h3>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              onKeyDown={handlePhoneNumberKeyDown}
              onInput={handlePhoneNumberInput}
              placeholder="01012345678"
              maxLength={11}
            />
          </div>
        )}

        {step === 4 && (
          <div className="step-3">
            <h3>나이를 입력해주세요</h3>
            <p>만 나이를 입력해주세요</p>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              onInput={handleAgeInput}
              placeholder="30"
              maxLength={2}
            />
          </div>
        )}

        {step === 5 && (
          <div className="step-4">
            <h3>어느 분야에서 일하고 있나요?</h3>
            <ul className="job-list">
              {fieldOptions.map((job) => (
                <li
                  key={job}
                  className={`job-item ${
                    formData.field === job ? "selected" : ""
                  }`}
                  onClick={() =>
                    handleChange({
                      target: {
                        name: "field",
                        value: job,
                      },
                    } as React.ChangeEvent<HTMLInputElement>)
                  }
                >
                  {job}
                </li>
              ))}
            </ul>
          </div>
        )}

        {step === 6 && (
          <div>
            <h3>구체적인 직업명을 입력해주세요</h3>
            <input
              type="text"
              name="job"
              value={formData.job}
              onChange={handleChange}
              placeholder="UXUI 디자이너"
            />
          </div>
        )}

        {step === 7 && (
          <div className="step-7">
            <h3>관심사를 선택해주세요</h3>
            <p>최대 5개를 선택해주세요</p>

            <div className="interest-category">
              <h4>문화 · 예술</h4>
              <div className="interest-options">
                {interestCultureOptions.map((option) => (
                  <div
                    key={option}
                    className={`interest-item ${
                      formData.interests.includes(option) ? "selected" : ""
                    }`}
                    onClick={() => handleMultiSelect(option, "interests")}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>

            <div className="interest-category">
              <h4>스포츠</h4>
              <div className="interest-options">
                {interestSportsOptions.map((option) => (
                  <div
                    key={option}
                    className={`interest-item ${
                      formData.interests.includes(option) ? "selected" : ""
                    }`}
                    onClick={() => handleMultiSelect(option, "interests")}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>

            <div className="interest-category">
              <h4>일상 · 여가</h4>
              <div className="interest-options">
                {interestDailyOptions.map((option) => (
                  <div
                    key={option}
                    className={`interest-item ${
                      formData.interests.includes(option) ? "selected" : ""
                    }`}
                    onClick={() => handleMultiSelect(option, "interests")}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {step === 8 && (
          <div className="step-8">
            <h3>1달 이상 체류했던 도시는?</h3>
            <p>최대 3개까지 입력해주세요</p>

            <input
              type="text"
              name="city1"
              value={formData.cities[0] || ""}
              onChange={(e) => handleCityChange(e, 0)}
              placeholder="서울"
            />
            <input
              type="text"
              name="city2"
              value={formData.cities[1] || ""}
              onChange={(e) => handleCityChange(e, 1)}
              placeholder="뉴욕"
            />
            <input
              type="text"
              name="city3"
              value={formData.cities[2] || ""}
              onChange={(e) => handleCityChange(e, 2)}
              placeholder="베를린"
            />
          </div>
        )}

        {step === 9 && (
          <div className="step-8">
            <h3>대화 가능한 언어는?</h3>
            <p>최대 3개까지 입력해주세요</p>

            <input
              type="text"
              name="languages1"
              value={formData.languages[0] || ""}
              onChange={(e) => handleLanguageChange(e, 0)}
              placeholder="한국어"
            />
            <input
              type="text"
              name="languages2"
              value={formData.languages[1] || ""}
              onChange={(e) => handleLanguageChange(e, 1)}
              placeholder="영어"
            />
            <input
              type="text"
              name="languages3"
              value={formData.languages[2] || ""}
              onChange={(e) => handleLanguageChange(e, 2)}
              placeholder="중국어"
            />
          </div>
        )}

        {step === 10 && (
          <div className="step-8">
            <h3>SNS를 등록해주세요</h3>
            <p>SNS가 비공개 계정이라면 팔로우를 수락해주세요</p>

            <input
              type="text"
              name="snsId1"
              value={formData.snsIds?.[0] || ""}
              onChange={(e) => handleSNSIdsChange(e, 0)}
              placeholder="인스타그램 아이디"
            />
            <input
              type="text"
              name="snsId2"
              value={formData.snsIds?.[1] || ""}
              onChange={(e) => handleSNSIdsChange(e, 1)}
              placeholder="링크드인 프로필 URL"
            />
            <input
              type="text"
              name="snsId3"
              value={formData.snsIds?.[2] || ""}
              onChange={(e) => handleSNSIdsChange(e, 2)}
              placeholder="기타 URL"
            />
          </div>
        )}

        {/* {step === 10 && (
          <div className="step-10">
            <h3>
              더 매력적인 프로필을 위해
              <br />
              아래 질문에 답해주세요
            </h3>
            <ul className="question-list">
              <li
                className={`question-item ${
                  formData.question1?.length > 5 ? "selected" : ""
                }`}
                onClick={() => {
                  handleQuestionChange(11);
                }}
              >
                함께 이야기 나누고 싶은 주제는?
              </li>
              <li
                className={`question-item ${
                  formData.question2?.length > 5 ? "selected" : ""
                }`}
                onClick={() => {
                  handleQuestionChange(12);
                }}
              >
                삶에서 중요하게 생각하는 가치는?
              </li>
              <li
                className={`question-item ${
                  formData.question3?.length > 5 ? "selected" : ""
                }`}
                onClick={() => {
                  handleQuestionChange(13);
                }}
              >
                나만 알고 있는 세상의 비밀은?
              </li>
            </ul>
          </div>
        )} */}

        {step === 11 && (
          <div className="step-11">
            <h3>
              마지막으로,
              <br />
              도넛 멤버들과 어떤 주제로
              <br />
              이야기 나누고 싶나요?
            </h3>
            <p>최소 2가지를 적어주세요</p>
            <textarea
              name="question1"
              value={formData.question1}
              onChange={handleChange}
              placeholder="내용을 입력해주세요"
              maxLength={90}
            />
          </div>
        )}

        {/* {step === 12 && (
          <div className="step-11">
            <h3>삶에서 중요하게 생각하는 가치는?</h3>
            <p>최대 90자까지 작성 가능해요</p>
            <textarea
              name="question2"
              value={formData.question2}
              onChange={handleChange}
              placeholder="내용을 입력해주세요"
              maxLength={90}
            />
          </div>
        )}

        {step === 13 && (
          <div className="step-11">
            <h3>나만 알고 있는 세상의 비밀은?</h3>
            <p>최대 90자까지 작성 가능해요</p>
            <textarea
              name="question3"
              value={formData.question3}
              onChange={handleChange}
              placeholder="내용을 입력해주세요"
              maxLength={90}
            />
          </div>
        )} */}

        {step === 12 && (
          <div className="step-14">
            <h3>
              지원 완료 :)
              <br />
              150명이 모이면 도넛이 시작돼요!
            </h3>
            <div className="done_card">
              <p className="name">{formData.name}님은</p>
              <p>{userNumber && <span>{userNumber}</span>}번째</p>
              <p>도넛이에요</p>
              <div className="cloud_box">
                <p className="box-text">
                  멤버십 카드를 인스타그램 스토리로
                  <br />
                  공유하고 @donut__app을 태그해주세요!
                  <br />
                  카톡으로 도넛 기프티콘을 보내드릴게요 🍩
                </p>
              </div>
            </div>

            <button
              onClick={() => handleDownload(enrollCardImageUrl!)}
              className="download-button"
            >
              저장하고 공유하기
            </button>
            <button
              onClick={() => (window.location.href = "/")}
              className="home-button"
            >
              홈으로
            </button>
          </div>
        )}
        {/* {step === 11 && (
          <div>
            <h3>더 매력적인 프로필을 위해 아래 질문에 답해주세요</h3>
            <h3>삶에서 중요하게 생각하는 가치는?</h3>
            <textarea
              name="question2"
              value={formData.question2}
              onChange={handleChange}
              placeholder="삶에서 중요하게 생각하는 가치는?"
            />
          </div>
        )}

        {step === 12 && (
          <div>
            <h3>더 매력적인 프로필을 위해 아래 질문에 답해주세요</h3>
            <h3>나만 알고 있는 세상의 비밀은?</h3>
            <textarea
              name="question3"
              value={formData.question3}
              onChange={handleChange}
              placeholder="나만 알고 있는 세상의 비밀은?"
            />
          </div>
        )} */}
      </div>

      {step < 12 && (
        <div className={`step-${step}-footer-button form-footer`}>
          <button onClick={handleNext} disabled={disableNext}>
            다음
          </button>
          {step === 1 && <p onClick={handleSkip}>건너뛰기</p>}
        </div>
      )}
    </div>
  );
};

export default StepForm;
