import { create } from "zustand";

export interface formDataFields {
  phone: string;
  name: string;
  age: number;
  job: string;
  field: string;
  interests: string[];
  cities: string[];
  languages: string[];
  question1: string;
  referrerCode: string;
  snsIds: string[];
}

interface FormDataState {
  formData: formDataFields;
  setFormData: (
    key: keyof FormDataState["formData"],
    value: string | string[]
  ) => void;
  resetFormData: () => void;
}

const loadFromLocalStorage = (): formDataFields => {
  const savedData = localStorage.getItem("formData");
  return savedData
    ? JSON.parse(savedData)
    : {
        phone: "",
        name: "",
        age: 0,
        job: "",
        field: "",
        interests: [],
        cities: [],
        languages: [],
        question1: "",
        card_url: "",
        referrerCode: "",
        snsIds: [],
      };
};

export const useFormStore = create<FormDataState>((set) => ({
  formData: loadFromLocalStorage(),
  setFormData: (key, value) =>
    set((state) => {
      const updatedFormData = {
        ...state.formData,
        [key]: value,
      };
      localStorage.setItem("formData", JSON.stringify(updatedFormData)); // 로컬 스토리지에 저장
      return { formData: updatedFormData };
    }),
  resetFormData: () => {
    localStorage.removeItem("formData"); // 리셋 시 로컬 스토리지에서도 제거
    return {
      formData: {
        phone: "",
        name: "",
        age: 0,
        job: "",
        field: "",
        interests: [],
        cities: [],
        languages: [],
        question1: "",
        card_url: "",
        referrerCode: "",
        snsIds: [],
      },
    };
  },
}));
